import { Fragment } from 'react'
import './Biography.css'

const Biography = props => {
  return (
    <Fragment>
      <div className='bio-container'>
      </div>
    </Fragment>
  )
}

export default Biography
