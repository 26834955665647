import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/Home/Home'
import Contact from './components/Contact/Contact'
import Live from './components/Live/Live'
import Media from './components/Media/Media'
import './App.css'

const App = () => {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/live' element={<Live />} />
          <Route path='/media' element={<Media />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
