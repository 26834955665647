import React from "react";
import Nav from "../Nav/Nav";
import "./Live.css";
import yojimboAtGigis from "../../images/yojimboAtGigis.jpg";

const Live = (props) => {
  return (
    <React.Fragment>
      <Nav />
      <div className="live-container">
        <div className="live-picture"></div>
        <div className="live-details">
          <div className="gig">
            <div className="gig-header">Gigi's - 824 w 36th St</div>
            <div className="gig-header">
              Gigi's cafe is hosting a Neighborhood Dinner Showcase from 6 to
              8pm on 10/25.
            </div>
            <div className="gig-header">
              Come join us for an evening of all original music from Jerry
              Shelton and John Brown!
            </div>
            <br />
            <img src={yojimboAtGigis} height="500px" alt="yojimbo at gigis" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Live;
