import React from 'react'
import Title from '../Title/Title'
import SectionOne from '../SectionOne/SectionOne'
import Biography from '../Biography/Biography'
import Nav from '../Nav/Nav'

const Home = props => {
  return (
    <React.Fragment>
      <Nav />
      <Title />
      <SectionOne />
      <Biography />
    </React.Fragment>
  )
}

export default Home
