import React from 'react'
import Nav from '../Nav/Nav'
import yojimboRecordingThumb from '../../images/YojimboRecordingThumb.png'
import livePoster from '../../images/livePoster.png'
import './Media.css'

const Media = props => {
  return (
    <React.Fragment>
      <Nav />
      <div class='image-gallery'>
        <img src={livePoster} alt='live poster' />
        <img src='image2.jpg' alt='Image 2' />
        <img src='image3.jpg' alt='Image 3' />
      </div>

      <div class='video-gallery'>
        <video src={yojimboRecordingThumb} controls></video>
        <video src='video2.mp4' controls></video>
        <video src='video3.mp4' controls></video>
      </div>
    </React.Fragment>
  )
}

export default Media
