import React from 'react'
import Nav from '../Nav/Nav'
import './Contact.css'

const Contact = props => {
  return (
    <React.Fragment>
      <Nav />
      <div className='live-container'>
        <div className='live-picture'>
        </div>
        <div className='contact-container'>
          <div className='contact-details'>Steve Klein: 612-269-9426</div>
          <div className='contact-details'>Email: yojimbojazzguys@gmail.com</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Contact
