import { Fragment } from 'react'
import './SectionOne.css'

const Container = props => {
  return (
    <Fragment>
      <div className='container'>
        <div className='section-one'>
          THE JAZZ YOU LOVE BY THE GENTLEMEN YOU'LL LEARN TO LOVE
        </div>
        <div className='section-one-sub'>
        FROM BIRD TO COLTRANE TO MILES TO MINGUS
        </div>
        <div className='section-one-detail'>
        Together almost 25 years Yo Jimbo Jazz takes its cue from the Jazz Messengers by bringing classic bop, 
        hard bop, latin & funk to new generations of listeners.  
        We play all kinds of events from weddings and birthday parties to happy hours and garden parties. 
        We can configure our group from a trio to the full sextet to accomodate your event space.
        </div>
      </div>
    </Fragment>
  )
}

export default Container
