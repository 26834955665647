import { Fragment } from 'react'
import './Title.css'
import andWhatHappenedThenCover from '../../images/andWhatHappenedThenCover.png'
import './Title.css'

const Title = props => {
  return (
    <Fragment>
      <div className='title'>Yojimbo Jazz</div>
      <div className='sub-title'>Classic</div>
      <div className='sub-title'>Improvisational</div>
      <div className='sub-title'>Jazz</div>
      <div className='album-description'>Check out our newest album available on Spotify or your favorite streaming service:</div>
      <a className='album-cover' href="https://open.spotify.com/artist/2XR632iLE06WXJXxRuYxRD?si=FAQrpRT1RAmAxRFy8SopAQ" target="_blank" rel="noreferrer">
        <img className='album-cover-image' src={andWhatHappenedThenCover} alt="albumcover"></img></a>
    </Fragment>
  )
}

export default Title
