import React from 'react'
import { Link } from 'react-router-dom'
import './Nav.css'

function Nav () {
  return (
    <React.Fragment>
      <div className='navigation'>
        <Link className='navlink' to='/home'>
          Home
        </Link>
        <Link className='navlink' to='/live'>
          Live
        </Link>
        <Link className='navlink' to='/contact'>
          Contact
        </Link>
        {/* <Link className='navlink' to='/media'>
          Media
        </Link> */}
      </div>
    </React.Fragment>
  )
}

export default Nav
